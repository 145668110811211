<template>
  <v-row class="px-4 py-2">
    <v-col class="pa-1" cols="12" sm="6" md="4">
      <v-menu
        v-model="menuDataIni"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="moment(dataInicio).format('MM/YYYY')"
            label="Mês / Ano"
            prepend-icon="mdi-calendar"
            color="teal"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="dataInicio"
          style="margin: 0px"
          :max="dataLimite"
          color="teal"
          locale="pt-br"
          type="month"
          @input="menuDataIni = false"
        />
      </v-menu>
    </v-col>
    <v-col class="pa-1" cols="12" sm="6" md="4">
      <!-- <v-menu
        v-model="menuDataFim"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="moment(dataFim).format('DD/MM/YYYY')"
            color="teal"
            label="Data Final"
            prepend-icon="mdi-calendar"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="dataFim"
          :min="dataInicio"
          :max="dataLimite"
          color="teal"
          locale="pt-br"
          @input="menuDataFim = false"
        />
      </v-menu> -->
    </v-col>
    <v-col class="pa-3" cols="12" sm="12" md="4">
      <v-row justify="end" align="center">
        <v-btn
          :disabled="searching"
          style="color: white; border-radius: 8px; margin-left: 40%"
          color="green darken-1"
          @click="dashboard(dataInicio)"
        >
          <span style="font-size: 16px; font-weight: 600">Buscar Período</span>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
// import { ENV_APP } from '../../env'
export default {
  name: 'DateInputs',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    draft: {
      type: Boolean,
      default: false,
    },
    //eslint-disable-next-line
    dashboard: Function,
  },
  data() {
    return {
      searching: true,
      dataLimite: this.moment().toISOString(true).substr(0, 10),
      menuDataIni: false,
    }
  },
  computed: {
    ...mapState(['relatorio_dataInicio', 'relatorio_dataFim']),
    dataInicio: {
      get() {
        return this.$store.state.relatorio_dataInicio
          ? this.$store.state.relatorio_dataInicio
          : this.moment()
              .subtract(1, 'months')
              .startOf('month')
              .toISOString(true)
              .substr(0, 10)
      },
      set(val) {
        this.$store.commit('SET_RELATORIO_DATAINICIO', val)
      },
    },
  },
  mounted() {
    this.searching = false
  },
  methods: {
    typeValidation(status) {
      if (status === 0) {
        return 'Não validado'
      } else if (status === 1) {
        return 'Válido'
      } else if (status === 2) {
        return 'Inconsistente'
      } else if (status === 3) {
        return 'Justificado'
      }

      return '-'
    },
  },
}
</script>
