<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Informações básicas</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" sm="6" lg="6" xl="4" class="col-bordered">
          <span class="key-title">Fazenda: </span>
          <span class="value-title">{{ getValue('fazenda') }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="4" class="col-bordered">
          <span class="key-title">Produtor(a): </span>
          <span class="value-title">{{ getValue('produtor') }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Matrícula: </span>
          <span class="value-title">{{ getValue('matricula') }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Consultor(a): </span>
          <span class="value-title">{{ getValue('nomeConsultor') }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Estado: </span>
          <span class="value-title">
            {{ getValue('estado') }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Cidade: </span>
          <span class="value-title">
            {{ getValue('cidade') }}
          </span>
        </v-col>

        <v-col cols="12" sm="6" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Data: </span>
          <span class="value-title">{{ getValue('dataAtendimento') }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Horário do ínicio: </span>
          <span class="value-title">{{ getValue('horaInicio') }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Horário do término: </span>
          <span class="value-title">{{ getValue('horaInicio') }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Número da visita: </span>
          <span class="value-title"
            >{{ getValue('numeroVisita') }}ª visita</span
          >
        </v-col>
        <RenderComponents
          v-for="component in itens"
          :key="component.name"
          :component="component"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'GeneralInformation',

  components: {
    RenderComponents: () => import('./RenderComponents.vue'),
  },

  props: {
    step: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('report', ['data', 'ready']),
    ...mapGetters('report', ['getValue']),

    itens() {
      return this.step?.fields.slice(4) ?? []
    },
  },

  methods: {},
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
