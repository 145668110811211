function signature({ label, value, type, props, sufixo }) {
  switch (type) {
    case 'assinatura':
      return onlySignature({ label, value, props })

    case 'signatureWithName':
      return signatureWithName({ label, value, props, sufixo })
  }
}

function onlySignature({ label, value, props }) {
  return `
  <div class="coluna ${props || ''} horizontal-center">
    <img class="image-assinatura" src="${value}" width="120" alt="Assinatura" />
    ${label !== '-' ? '<span>' + label + '</span>' : ''}
  </div>`
}

function signatureWithName({ label, value, props, sufixo }) {
  if (!sufixo) sufixo = ''

  return `
  <div class="coluna c50 ${props || ''} horizontal-center">
    <img class="image-assinatura" src="${value}" width="120" alt="Assinatura" />
    <div style="width: 90%; border-bottom: 1px solid black; margin: 0 auto"></div>
    ${label ? '<span>' + label + '</span>' : ''}
    ${sufixo ? '<span>' + sufixo + '</span>' : ''}
  </div>
  `
}

export { signature }
