<template>
  <v-col v-if="getValue(component.name)" :cols="cols" class="col-bordered">
    <v-row class="ml-1 mr-1 justify-center align-center">
      <span class="key-title px-1 py-1"
        >{{ component.label ?? 'Assinatura' }}:

        <v-img
          :src="getImagem(getValue(component.name))"
          contain
          width="200px"
          height="300px"
          :alt="component.placeholder ?? component.label"
          class="signature"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </span>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import { ENV_APP } from '../../../../../env'

export default {
  name: 'Signature',

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('report', ['getValue', 'processRecursively']),

    cols() {
      const signatures = []

      this.processRecursively(field => {
        if (field.componentType == 'btnAssinatura') {
          signatures.push(field)
        }
      })

      if (signatures.length == 1) return 12

      return 6
    },
  },

  methods: {
    getImagem(id) {
      if (id.length > 36) return id

      return `${ENV_APP.apiUri}/signature/${id}`
    },
  },
}
</script>

<style>
.signature {
  transform: rotate(90deg);
  margin-top: -40px;
  margin-bottom: -40px;
  border: solid 2px #333;
  border-radius: 10px;
}
</style>
