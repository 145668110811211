<template>
  <v-col v-if="showGroupField" cols="12">
    <span class="key-title">{{ component.label }}</span>
    <v-row
      v-if="component.fields"
      class="col-bordered value-title ml-1 mr-1"
      width="98%"
    >
      <RenderComponents
        v-for="field in component.fields"
        :key="field.name"
        :component="field"
      ></RenderComponents>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GroupField',

  components: {
    RenderComponents: () => import('./RenderComponents.vue'),
  },

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('report', ['getValue']),

    showGroupField() {
      return this.isSubFieldItems()
    },
  },

  methods: {
    recursive(fields) {
      for (const field of fields) {
        if (!field?.name) return

        const value = this.getValue(field.name)

        if (value && (value == '' || value == '0')) {
          return false
        }

        if (value && (value == '1' || value.length > 0)) {
          return true
        }

        if (field?.fields) {
          if (field.fields && field.fields.length > 0) {
            return true
          }
        }

        if (field.fields && field.fields.length > 0) {
          return this.recursive(field.fields)
        }
      }

      return false
    },

    isSubFieldItems() {
      return this.recursive(this.component.fields)
    },
  },
}
</script>

<style></style>
