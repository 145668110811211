<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on }">
      <v-btn icon @click="dialog = true" v-on="on">
        <v-icon color="primary">mdi-eye</v-icon>
      </v-btn>
    </template>

    <v-card v-if="dialog">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ reportLabel }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div>
        <base-loading v-if="ready" />

        <v-card v-else>
          <v-card-title style="justify-content: center; font-size: 24px">
            <v-row justify="center">
              <v-col cols="12">
                <v-row justify="center">
                  {{ reportLabel }}
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row justify="center"
                  >{{ report.NumeroVisita }}ª visita</v-row
                >
              </v-col>
            </v-row>
          </v-card-title>

          <ReportView id="report-show" />

          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              :loading="loadingPDF"
              :disabled="loadingPDF"
              dense
              @click="print"
            >
              <v-icon>mdi-file-pdf-box</v-icon> Exportar PDF
            </v-btn>
            <v-btn
              color="red"
              class="white--text"
              dense
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon> Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Toast from '../../../../plugins/toast'
import reportType from '../../../../services/api/reportType'
import PDFGenerator from '../../../../services/StructureReport/Pdf/PDFGenerator'
import { LOAD_REPORT, LOAD_STRUCTURE } from '../../../../vuex/modules/report'
import ReportView from './ReportView.vue'

export default {
  name: 'ReportShow',

  components: {
    ReportView,
  },

  props: {
    report: {
      type: Object,
      required: true,
    },

    reportTypeId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      loadingPDF: false,
    }
  },

  computed: {
    ...mapState('reportType', ['reportTypes']),
    ...mapState('report', ['data', 'structure', 'ready']),

    reportLabel() {
      return (
        this.reportTypes.find(r => Number(r.id) == Number(this.reportTypeId))
          ?.name ?? 'Relatório'
      )
    },
  },

  watch: {
    dialog: {
      immediate: true,
      async handler() {
        if (this.dialog) {
          await this.LOAD_STRUCTURE(this.reportTypeId)
          await this.LOAD_REPORT(this.report.CodRelatorio)
        }
      },
    },
  },

  methods: {
    ...mapActions('report', [LOAD_REPORT, LOAD_STRUCTURE]),

    async print() {
      try {
        this.loadingPDF = true
        let layout = await reportType.getLayout({ id: this.reportTypeId })
        layout = JSON.parse(layout.data.dados)

        const pdfGenerator = new PDFGenerator(this.structure, layout, this.data)

        await pdfGenerator.create(this.structure.title, true)
      } catch (error) {
        Toast().fire({
          icon: 'error',
          title: `Erro ao exportar PDF: ${error}`,
        })
      } finally {
        this.loadingPDF = false
      }
    },
  },
}
</script>
